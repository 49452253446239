<template>
  <div class="matchKeywordsTable">
    <el-table
      class="variable-rules"
      fit
      :data="keywordMatches"
      style="width: 100%;"
    >
      <el-table-column prop="keyword" :label="__('Weight')">
        <template slot-scope="scope">
          <el-row type="flex">
            <el-col :class="classes(scope.row, 'keyword')">
              <el-form-item :error="scope.row.keyword_error">
                <el-input
                  v-model="scope.row.keyword"
                  @change="handleKeywordChange($event)(scope.row)"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column prop="node_name" :label="__('Goto Node')">
        <template slot-scope="scope">
          <el-row
            :gutter="20"
            class="goto-node-configure"
            type="flex"
            justify="start"
          >
            <el-col :class="classes(scope.row, 'node_name')">
              <el-form-item>
                <create-or-select
                  :items="extractGotoNodeOptions"
                  :current_select="currentSelection(scope.row)"
                  :fail-to-create-message="scope.row.error"
                  :new-item-message="__('new node')"
                  :placeholder="__('Node Name')"
                  @change="handleChange($event)(scope.row)"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column class-name="row-message" width="100px" prop="msg" />
      <el-table-column class-name="cell-item-pointer" width="40px">
        <template slot-scope="scope">
          <span @click="removeKeywordMatch(scope.$index)">
            <i class="el-icon-circle-close"></i>
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import CreateOrSelect from "./CreateOrSelect";

export default {
  components: {
    CreateOrSelect
  },
  props: {
    value: {
      required: true,
      type: Array
    },
    gotoOptions: {
      required: true,
      type: Array
    }
  },
  data() {
    const keyword_match = {
      keyword: "",
      node_name: "",
      node_id: -1,
      weight_type: "percentage",
      keyword_text: ""
    };
    return {
      keyword_match,
      $keyword_matches: [{ ...keyword_match }],
      $gotoOptions: []
    };
  },
  computed: {
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit
    }),

    extractGotoNodeOptions() {
      const options = _.map(this.gotoOptions, child => ({
        label: child.node_name,
        value: child.node_id
      }));
      return this.$data.$gotoOptions.concat(options);
    },

    keywordMatches() {
      return this.$data.$keyword_matches;
    },

    currentSelection() {
      return row => {
        const { node_id, node_name } = row;
        return node_id === -1 ? node_name : node_id;
      };
    },

    classes() {
      return (row, key) => {
        let counter = {};

        this.keywordMatches.forEach(function(obj) {
          let key = JSON.stringify(obj);
          counter[key] = (counter[key] || 0) + 1;
        });

        let rowStringified = JSON.stringify(row);
        if (counter[rowStringified] <= 1) {
          return "no-red-highlight";
        }

        if (!row.keyword && !row.node_name) {
          return "empty-row empty-val no-red-highlight";
        }
        if (!row[key]) {
          return "empty-val";
        } else {
          return "non-empty-val";
        }
      };
    }
  },
  methods: {
    addNewKeywordMatch() {
      this.$data.$keyword_matches.push({ ...this.keyword_match });
    },

    removeKeywordMatch(index) {
      this.$data.$keyword_matches.splice(index, 1);
    },

    initializeKeyWordMatches(keywordMatches) {
      if (!this.isNodeSubmit) {
        if (!_.isEmpty(keywordMatches) && keywordMatches.length) {
          this.$data.$keyword_matches = _.cloneDeep(keywordMatches);
        }
        if (
          !_.some(
            this.$data.$keyword_matches,
            keywordMatch => !keywordMatch.keyword.length
          )
        ) {
          this.addNewKeywordMatch();
          this.$emit("input", _.cloneDeep(this.$data.$keyword_matches));
        }
      }
    },

    handleKeywordChange(value) {
      return row => {
        this.$set(row, "keyword_text", value);
      };
    },

    handleChange(option) {
      return row => {
        this.$set(row, "node_id", option.value);
        this.$set(row, "node_name", option.label);
        this.$set(row, "msg", option.msg);
      };
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "initializeKeyWordMatches"
    },
    keywordMatches: {
      handler: function(keywordMatches) {
        this.$emit("input", _.cloneDeep(keywordMatches));
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
/*.goto-node-configure {*/
/*  flex-direction: column;*/
/*}*/
/*.goto-node-configure .el-form-item {*/
/*  margin-bottom: 0;*/
/*}*/

/*.goto-node-configure ::v-deep .el-form-item__content {*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  justify-content: space-between;*/
/*}*/

/*.goto-node-configure ::v-deep .el-form-item__error {*/
/*  flex-grow: 1;*/
/*  flex-shrink: 0;*/
/*  align-self: center;*/
/*}*/

.matchKeywordsTable ::v-deep .el-table--medium td {
  padding: 0;
}

.matchKeywordsTable ::v-deep .el-table--medium th {
  padding: 0;
}

.el-table {
  .el-form-item {
    padding-bottom: 15px;
    /*padding-top: 4px;*/
  }
}

.matchKeywordsTable ::v-deep .el-form-item__error {
  padding-top: 1px;
  font-size: 10px;
}

.matchKeywordsTable ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.variable-rules ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.matchKeywordsTable ::v-deep .el-table::before {
  background-color: white !important;
}

.matchKeywordsTable ::v-deep .el-table__body-wrapper {
  margin-top: 10px;
}

.matchKeywordsTable {
  ::v-deep .no-red-highlight .el-input__inner,
  ::v-deep .no-red-highlight .el-textarea__inner,
  ::v-deep .empty-row .el-input__inner,
  ::v-deep .empty-row .el-textarea__inner {
    border-color: $--border-color-base !important;

    &:focus {
      border-color: $--color-text-regular !important;
    }
  }
}
</style>
