<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="node_name" :label="__('Name')">
            <el-input v-model="nodeToBind.node_name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="24" style="min-width:500px">
          <el-form-item
            prop="keyword_rule_weight"
            :label="__('Weight Allocation')"
            class="is-required"
          >
            <distribute-weight
              v-model="percentageConditions"
              :gotoOptions="generateGotoOptions"
              style="width: 100%;"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="24" style="min-width:500px">
          <el-form-item
            prop="keyword_rule_variable"
            :label="__('Variable Allocation')"
            class="is-required"
          >
            <distribute-variable
              v-model="variableConditions"
              :gotoOptions="generateGotoOptions"
              :variable-options="generateVariableOptions"
              style="width: 100%;"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "../BaseNode";
import DistributeWeight from "../components/DistributeWeight";
import DistributeVariable from "../components/DistributeVariable";
import _ from "lodash";
// import { filterRowsIfSomeKeyValueIsAbsent, getSubKeyObject } from "@/utils/collection";
import { filterRowsIfSomeKeyValueIsAbsent } from "@/utils/collection";
// import { mapGetters, mapState } from "vuex";
import { NODE_TYPES } from "@/constants/nodes";

export default {
  mixins: [BaseNode],
  components: {
    DistributeWeight,
    DistributeVariable
  },
  data() {
    const validateKeywordConditionsWeight = (rule, value, callback) => {
      if (
        this.nodeToBind.distribute_node.data.keyword_conditions.data.length < 1
      ) {
        callback(__("Define at least one distribute condition"));
      } else {
        _.map(
          this.nodeToBind.distribute_node.data.keyword_conditions.data,
          function(obj) {
            if (
              (obj.keyword_text === "" &&
                obj.node_name !== "" &&
                obj.node_id > 0) ||
              (obj.keyword_text === "{{-1}}" &&
                obj.keyword === "-1" &&
                obj.node_name !== "" &&
                obj.node_id > 0)
            ) {
              callback(
                // eslint-disable-next-line
                __("distribute condition cannot be defined with empty variable name")
              );
            }
          }
        );
        let weight_nodes = _.filter(
          this.nodeToBind.distribute_node.data.keyword_conditions.data,
          function(item) {
            return item.weight_type === "percentage" && item.node_id !== -1;
          }
        );
        if (weight_nodes.length) {
          const set = new Set(weight_nodes.map(JSON.stringify));
          if (set.size < weight_nodes.length) {
            callback(__("repeating weight allocations are not allowed"));
          }
        }
        callback();
      }
    };
    const validateKeywordConditionsVariable = (rule, value, callback) => {
      if (
        this.nodeToBind.distribute_node.data.keyword_conditions.data.length < 1
      ) {
        callback(__("Define at least one distribute condition"));
      } else {
        _.map(
          this.nodeToBind.distribute_node.data.keyword_conditions.data,
          function(obj) {
            if (
              (obj.keyword_text === "" &&
                obj.node_name !== "" &&
                obj.node_id > 0) ||
              (obj.keyword_text === "{{-1}}" &&
                obj.keyword === "-1" &&
                obj.node_name !== "" &&
                obj.node_id > 0)
            ) {
              callback(
                // eslint-disable-next-line
                __("distribute condition cannot be defined with empty variable name")
              );
            }
          }
        );
        let variable_nodes = _.filter(
          this.nodeToBind.distribute_node.data.keyword_conditions.data,
          function(item) {
            return item.weight_type === "variable" && item.node_id !== -1;
          }
        );
        if (variable_nodes.length) {
          const set = new Set(variable_nodes.map(JSON.stringify));
          if (set.size < variable_nodes.length) {
            callback(__("repeating variable allocations are not allowed"));
          }
        }
        callback();
      }
    };

    return {
      rules: {
        keyword_rule_weight: {
          validator: validateKeywordConditionsWeight,
          trigger: "change"
        },
        keyword_rule_variable: {
          validator: validateKeywordConditionsVariable,
          trigger: "change"
        }
      }
    };
  },
  computed: {
    allKeywordConditions() {
      // console.log(
      //   this.nodeToBind.distribute_node.data.keyword_conditions,
      //   "distribute"
      // );
      return _.isEmpty(this.nodeToBind.distribute_node.data.keyword_conditions)
        ? { data: [] }
        : {
            data: _.map(
              this.nodeToBind.distribute_node.data.keyword_conditions.data,

              keywordCondition => {
                keywordCondition["error"] = "";
                if (keywordCondition.node_id === -1) {
                  keywordCondition["error"] = !_.map(
                    this.getValidNodes,
                    "node_name"
                  ).includes(keywordCondition.node_name)
                    ? ""
                    : __("Node ':nodeName' cannot be used here", {
                        nodeName: keywordCondition.node_name
                      });
                }
                if (
                  keywordCondition.node_id === -1 &&
                  keywordCondition.node_name.toLowerCase() === "disconnect"
                ) {
                  keywordCondition["error"] =
                    "'" +
                    `${keywordCondition.node_name}` +
                    "' " +
                    __("cannot be used as node name");
                }

                keywordCondition["keyword_error"] = isNaN(
                  keywordCondition.keyword
                )
                  ? __("Percentage should be a number")
                  : "";

                keywordCondition["msg"] = !keywordCondition.error
                  ? keywordCondition["msg"] || ""
                  : "";

                return keywordCondition;
              }
            )
          };
    },
    percentageConditions: {
      get: function() {
        const { data: keyword_conditions } = this.allKeywordConditions;
        return _.isEmpty(keyword_conditions)
          ? []
          : _.filter(
              keyword_conditions,
              condition => condition.weight_type === "percentage"
            );
      },
      set: function(val) {
        const percentageConditionOnly = this.percentageConditions;
        // console.log(percentageConditionOnly);
        if (
          // update only if there is a change in keyword conditions excluding the otherwise node
          !_.isEqual(percentageConditionOnly, val) ||
          _.isEmpty(percentageConditionOnly)
        ) {
          const variableCondition = this.variableConditions;
          val = val.concat(variableCondition);
          // console.log(val);
          this.$set(
            this.nodeToBind.distribute_node.data.keyword_conditions,
            "data",
            val
          );
        }
      }
    },
    variableConditions: {
      get: function() {
        const { data: keyword_conditions } = this.allKeywordConditions;
        return _.isEmpty(keyword_conditions)
          ? []
          : _.filter(
              keyword_conditions,
              condition => condition.weight_type === "variable"
            );
      },
      set: function(val) {
        const variableConditionOnly = this.variableConditions;
        // console.log(variableConditionOnly);
        if (
          // update only if there is a change in keyword conditions excluding the otherwise node
          !_.isEqual(variableConditionOnly, val) ||
          _.isEmpty(variableConditionOnly)
        ) {
          const percentageCondition = this.percentageConditions;
          //Always keep percentage before variable type weights
          val = percentageCondition.concat(val);
          // console.log(val);
          this.$set(
            this.nodeToBind.distribute_node.data.keyword_conditions,
            "data",
            val
          );
        }
      }
    },

    generateVariableOptions() {
      return _.filter(this.variables, variable => {
        return !["array", "audio", "system", "payment", "secure"].includes(
          variable.variable_type
        );
      });
    },

    /**
     * method to do extra checks to validate form, it cannot be handled by the element UI form validations
     * @returns {boolean}
     */
    formHasErrors() {
      const { data: keyword_conditions } = this.allKeywordConditions;

      return (
        _.some(keyword_conditions, condition => {
          return condition.error && condition.error.length;
        }) ||
        _.some(keyword_conditions, condition => {
          return condition.keyword_error && condition.keyword_error.length;
        })
      );
    }
  },
  methods: {
    findKeywordCondition(conditions, keyword) {
      const condition = _.cloneDeep(_.find(conditions, { keyword }));
      return _.isEmpty(condition) ? {} : condition;
    },

    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);
      // cleanup keyword_matches
      const keywordConditions = _.sortBy(
        _.map(
          filterRowsIfSomeKeyValueIsAbsent(
            _.map(this.allKeywordConditions.data, keywordCondition => ({
              ...keywordCondition,
              keyword: keywordCondition.keyword,
              node_name: keywordCondition.node_name.toString().trim(),
              weight_type: keywordCondition.weight_type.toString().trim()
            })),
            "keyword,node_name"
          ),
          condition => {
            const {
              node_id,
              node_name,
              keyword,
              weight_type,
              keyword_text
            } = condition;
            return {
              node_id,
              node_name,
              keyword,
              weight_type,
              keyword_text
            };
          }
        ),
        "node_id"
      );
      this.$set(
        nodeToCleanUp.distribute_node.data.keyword_conditions,
        "data",
        keywordConditions
      );
      return nodeToCleanUp;
    },

    cleanUpNode() {
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    }
  },
  created() {
    if (
      !this.nodeToBind.node_id ||
      _.isEmpty(this.nodeToBind.distribute_node)
    ) {
      this.$set(this.nodeToBind, "distribute_node", {});
      this.$set(this.nodeToBind.distribute_node, "data", {});
      if (_.isEmpty(this.nodeToBind.distribute_node.data.keyword_conditions)) {
        this.$set(
          this.nodeToBind.distribute_node.data,
          "keyword_conditions",
          {}
        );
      }

      this.$set(this.nodeToBind, "node_type", NODE_TYPES.DISTRIBUTE.NODE_TYPE);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node_common.scss";

.tabPane {
  max-height: 52vh;
  padding-right: 30px;
}
</style>
